import $ from '../core/Dom';

export default (el, props) => {
    const dom = $(el);
    const button = dom.find('[data-button]');
    const status = dom.find('[data-status]');

    const numberOfArticles = props.layoutSize;

    const showMore = () => {
        const hiddenItems = dom.find('.hidden[data-list-item]');
        hiddenItems.slice(0, numberOfArticles).removeClass('hidden');
        const visibleItems = dom.find('[data-list-item]:not(.hidden)').length;
        const hasMoreArticles = hiddenItems.length > numberOfArticles;
        const statusText = props.statusText.replace('%X%', visibleItems) + (!hasMoreArticles ? ` - ${props.emptyText}` : '');
        status.text(statusText);
        hiddenItems.first().find('a').get(0).focus();
        if (!hasMoreArticles) {
            button.addClass('hidden');
        }
    };

    const init = () => {
        button.on('click', showMore);
    };

    const destroy = () => {
        button.off('click', showMore);
    };

    return {
        init,
        destroy
    };
};
