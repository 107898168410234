import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const toggles = dom.find('[aria-expanded]');

    const toggle = e => {
        const button = $(e.triggerTarget);
        button.attr('aria-expanded', button.attr('aria-expanded') === 'false' ? 'true' : 'false');
    };

    const init = () => {
        toggles.on('click', toggle);
    };

    const destroy = () => {
        toggles.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
