import gsap from 'gsap';
import $ from '../core/Dom';
import MediaControl from '../lib/MediaControl';

export default el => {
    const dom = $(el);

    const DEFAULT_DURATION = 5;
    const slides = dom.find('[data-slide]').nodes;
    const bars = dom.find('[data-progress]');

    let playing = false;
    let timeline;
    let playable;

    const play = () => {
        MediaControl.play(playable);
        timeline.play();
        playing = true;
        toggleVideoPlayback(false);
    };

    const pause = () => {
        timeline.pause();
        playing = false;
        toggleVideoPlayback(true);
    };

    const togglePlayback = () => {
        if (playing) {
            pause();
        } else {
            play();
        }
    };

    const onTimeUpdate = e => {
        const video = e.target;
        if (video.currentTime > 0.001) {
            video.removeEventListener('timeupdate', onTimeUpdate);
            timeline.resume();
        }
    };

    const toggleVideoPlayback = paused => {
        const video = $(slides[0]).find('[data-video]').get(0);
        if (video) {
            if (paused) {
                video.pause();
            } else {
                video.play();
            }
        }
    };

    const ifVideoPlayIt = slide => {
        const video = $(slide).find('[data-video]').get(0);
        if (video) {
            if (video.getAttribute('src')) {
                video.currentTime = 0;
                video.play();
            } else {
                timeline.pause();
                video.addEventListener('timeupdate', onTimeUpdate);
                video.setAttribute('src', video.getAttribute('data-src'));
            }
        }
    };

    const swapSlide = () => {
        const first = slides.shift();
        slides.push(first);
        slides.forEach((slide, index) => {
            const classesToAdd = `placed-image z-${(slides.length - 1) - index}${(index !== 0 ? ' hidden' : '')}`;
            if (index === 0) {
                ifVideoPlayIt(slide);
            }
            slide.removeAttribute('class');
            $(slide).addClass(classesToAdd);
        });
    };

    const onStart = () => {
        ifVideoPlayIt(slides[0]);
    };

    const onRestart = () => {
        dom.off('click', onRestart);
        dom.on('click', togglePlayback);
        playing = true;
        timeline.restart();
        swapSlide();
    };

    const onComplete = () => {
        playing = false;
        dom.off('click', togglePlayback);
        dom.on('click', onRestart);
    };

    const initTimeline = () => {
        timeline = gsap.timeline({ paused: true, onStart });
        slides.forEach((slide, index) => {
            const isLastSlide = index === slides.length - 1;
            const videoDuration = $(slide).find('[data-video]').attr('data-video');
            const duration = videoDuration ? parseFloat(videoDuration) : DEFAULT_DURATION;
            timeline.to(bars.get(index), {
                scaleX: 1,
                duration,
                ease: 'linear',
                onComplete: isLastSlide ? onComplete : swapSlide
            });
        });
        playable = { play, pause };
        MediaControl.addPlayer(playable);
    };

    const init = () => {
        initTimeline();
        dom.on('click', togglePlayback);
    };

    const destroy = () => {
        dom.off('click');
    };

    return {
        init,
        destroy
    };
};
