import gsap from 'gsap';
import $ from '../core/Dom';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default el => {
    const dom = $(el);
    const shapes = dom.find('path, circle').nodes;

    const init = () => {
        loadScrollTrigger(module => {
            const ScrollTrigger = module.default;
            gsap.registerPlugin(ScrollTrigger);

            shapes.forEach(shape => {
                const offsetX = gsap.utils.random(-128, 128, 24);
                const offsetY = gsap.utils.random(340, 400, 24);
                gsap.to(shape, {
                    x: `+=${offsetX}`,
                    y: `+=${offsetY}`,
                    scrollTrigger: {
                        start: 'top top',
                        end: 'bottom bottom',
                        scrub: 2
                    }
                });
            });
        });
    };

    const destroy = () => {};

    return {
        init,
        destroy
    };
};
