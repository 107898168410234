import Flickity from 'flickity';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {
    const dom = $(el);
    const wrapper = dom.find('[data-slides]').get(0);
    const prevnext = dom.find('[data-prevnext]');
    const prev = dom.find('[data-prev]');
    const next = dom.find('[data-next]');
    const slides = dom.find('[data-slide]');
    let flkty = null;

    const fixAriaHidden = () => {
        if (flkty) {
            flkty.cells.forEach(({ element }) => {
                element.removeAttribute('aria-hidden');
            });
        }
    };

    const checkStatus = () => {
        if (flkty) {
            prev.attr('disabled', flkty.prevButton.isEnabled ? null : 'disabled');
            next.attr('disabled', flkty.nextButton.isEnabled ? null : 'disabled');
            fixAriaHidden();
        }
    };

    const initSlider = () => {
        console.log('initSlider');
        flkty = new Flickity(wrapper, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: true,
            pageDots: false,
            wrapAround: false,
            groupCells: true,
            selectedAttraction: 0.09,
            friction: 0.55,
            adaptiveHeight: false,
            setGallerySize: false,
            resize: false
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
            flkty.slider.style.pointerEvents = 'none';
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
            flkty.slider.style.pointerEvents = 'auto';
        });

        prev.on('click', () => {
            flkty.previous();
        });

        next.on('click', () => {
            flkty.next();
        });

        flkty.on('select', checkStatus);
        flkty.on('ready', checkStatus);

        slides.on('focusin', e => {
            wrapper.parentNode.scrollLeft = 0;
            const { triggerTarget: link } = e;
            const cell = flkty.cells.find(({ element }) => element.contains(link));
            if (!cell) {
                return;
            }
            const index = flkty.cells.indexOf(cell);
            if (index > -1) {
                flkty.selectCell(index);
            }
        });

        dom.find('.flickity-prev-next-button').attr('tabIndex', -1);

        prevnext.removeClass('hidden');
        checkStatus();
    };

    const onResize = () => {
        if (flkty) {
            flkty.resize();
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        initSlider();
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
