import gsap from 'gsap';
import $ from '../core/Dom';
import MediaControl from '../lib/MediaControl';

const loadVimeo = require('bundle-loader?lazy&name=[name]!@vimeo/player');

export default el => {
    const dom = $(el);
    const button = dom.find('[data-button]');
    const embed = dom.find('[data-embed]');

    let player;

    const onPlay = () => {
        MediaControl.play(player);
    };

    const loadPlayer = e => {
        e.preventDefault();
        button.off('click', loadPlayer);
        loadVimeo(module => {
            const VimeoPlayer = module.default;
            const color = getComputedStyle(el).getPropertyValue('--card-highlight').replace('#', '');
            embed.html(embed.data('embed').replace('666666', color));

            player = new VimeoPlayer(embed.find('iframe').get(0));
            MediaControl.addPlayer(player);
            player.on('play', onPlay);

            gsap.to(embed.get(0), {
                duration: 1,
                autoAlpha: 1,
                ease: 'power2.inOut'
            });
        });
    };

    const init = () => {
        button.on('click', loadPlayer);
    };

    const destroy = () => {
        button.off('click', loadPlayer);
    };

    return {
        init,
        destroy
    };
};
