import Flickity from 'flickity';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const dom = $(el);
    const wrapper = dom.find('[data-slides]').get(0);
    const slides = $(wrapper.children);
    let flkty = null;

    const checkStatus = () => {
        if (flkty) {
            flkty.cells.forEach(({ element }) => {
                element.removeAttribute('aria-hidden');
            });
        }
    };

    const getAutoPlayValue = () => {
        const { size } = Viewport.breakpoint;
        if (size < 744) {
            return parseInt(props.delayMobile, 10);
        } else if (size >= 744 && size < 1280) {
            return parseInt(props.delayTablet, 10);
        }
        return parseInt(props.delayDesktop, 10);
    };

    const initSlider = () => {
        const autoPlayValue = getAutoPlayValue();
        console.log('initSlider', autoPlayValue);
        flkty = new Flickity(wrapper, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'center',
            prevNextButtons: false,
            pageDots: false,
            freeScroll: true,
            wrapAround: true,
            selectedAttraction: 0.04,
            friction: 0.4,
            autoPlay: autoPlayValue
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
            flkty.slider.style.pointerEvents = 'none';
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
            flkty.slider.style.pointerEvents = 'auto';
        });

        flkty.on('pointerUp', () => {
            flkty.player.play();
        });

        flkty.on('select', checkStatus);
        flkty.on('ready', checkStatus);

        slides.on('focusin', e => {
            wrapper.parentNode.scrollLeft = 0;
            const { triggerTarget: link } = e;
            const cell = flkty.cells.find(({ element }) => element.contains(link));
            if (!cell) {
                return;
            }
            const index = flkty.cells.indexOf(cell);
            if (index > -1) {
                flkty.selectCell(index);
            }
        });

        checkStatus();
    };

    const getTallestSlide = () => {
        let height = 0;
        slides.each(node => {
            const currentHeight = node.offsetHeight;
            if (currentHeight > height) {
                height = currentHeight;
            }
        });
        return height;
    };

    const onResize = () => {
        if (flkty) {
            slides.css('height', null);
            flkty.resize();
            slides.height(getTallestSlide());
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        initSlider();
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
