import gsap from 'gsap';
import $ from '../core/Dom';
import MediaControl from '../lib/MediaControl';

export default el => {
    const dom = $(el);
    const progress = dom.find('[data-progress]').get(0);
    const scrubber = dom.find('[data-scrubber]');
    const time = dom.find('[data-time]');
    const buttonPlay = dom.find('[data-play]');
    const buttonPause = dom.find('[data-pause]');

    let audio;
    let playing = false;
    let loaded = false;

    const progressTween = gsap.to(progress, { paused: true, duration: 1, ease: 'linear', scaleX: 1 });

    const padNumber = number => (number > 9 ? number : `0${number}`);

    const onScrub = e => {
        if (e.offsetX) {
            const percent = e.offsetX / scrubber.width();
            audio.currentTime = audio.duration * percent;
        } else {
            toggleState();
        }
    };

    const onStateChanged = e => {
        console.log(e);
        playing = e.type === 'play' || e.type === 'playing';
        if (playing) {
            MediaControl.play(audio);
        }
        buttonPlay.toggleClass('hidden', playing);
        buttonPause.toggleClass('hidden', !playing);
    };

    const onTimeUpdate = () => {
        const minutes = padNumber(Math.floor(audio.currentTime / 60));
        const seconds = padNumber(Math.floor(audio.currentTime - minutes * 60));
        time.text(`${minutes}:${seconds}`);
    };

    const updateProgress = () => {
        const percent = audio.currentTime / audio.duration;
        progressTween.progress(percent);
    };

    const onProgress = () => {
        if (playing) {
            updateProgress();
        }
        requestAnimationFrame(onProgress);
    };

    const onSeeked = () => {
        if (!playing) {
            updateProgress();
            audio.play();
        }
    };

    const onCanPlay = () => {
        if (!loaded || playing) {
            loaded = true;
            audio.play();
            requestAnimationFrame(onProgress);
        }
    };

    const toggleState = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play();
        }
    };

    const initAudio = () => {
        audio = new Audio(dom.data('src'));
        audio.addEventListener('canplay', onCanPlay);
        audio.addEventListener('timeupdate', onTimeUpdate);
        audio.addEventListener('play', onStateChanged);
        audio.addEventListener('playing', onStateChanged);
        audio.addEventListener('pause', onStateChanged);
        audio.addEventListener('ended', onStateChanged);
        audio.addEventListener('seeked', onSeeked);

        scrubber.on('click', onScrub);
        buttonPlay.on('click', toggleState);
        buttonPause.on('click', toggleState);
        MediaControl.addPlayer(audio);
    };

    const onLoadAudio = () => {
        buttonPlay.off('click', onLoadAudio);
        scrubber.off('click', onLoadAudio);
        scrubber.removeClass('cursor-pointer').addClass('cursor-ew-resize');
        initAudio();
    };

    const init = () => {
        scrubber.on('click', onLoadAudio);
        buttonPlay.on('click', onLoadAudio);
    };

    const destroy = () => {
        loaded = false;
        playing = false;
        if (audio) {
            audio.pause();
            audio.removeEventListener('canplay', onCanPlay);
            audio.removeEventListener('timeupdate', onTimeUpdate);
            audio.removeEventListener('play', onStateChanged);
            audio.removeEventListener('playing', onStateChanged);
            audio.removeEventListener('pause', onStateChanged);
            audio.removeEventListener('ended', onStateChanged);
            audio.removeEventListener('seeked', onSeeked);
        }
        scrubber.off('click');
        buttonPlay.off('click');
        buttonPause.off('click');
    };

    return {
        init,
        destroy
    };
};
