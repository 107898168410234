import gsap from 'gsap';
import Headroom from 'headroom.js';
import { Quart, Sine, Linear, Quint, Circ } from 'gsap/gsap-core';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { HEADER_FREEZE, HEADER_UNFREEZE } from '../lib/events';

export default el => {
    const dom = $(el);
    const html = $('html');
    const body = $('body');
    const menu = dom.find('[data-menu]').get(0);
    const header = dom.find('[data-header]').get(0);

    const siteMenu = dom.find('[data-site-menu]');
    const siteToggle = dom.find('[data-site-toggle]');
    const searchButton = dom.find('[data-search]');
    const searchField = dom.find('#menu-search-query');

    const hamburger = dom.find('[data-hamburger]');
    const hamburgerTop = hamburger.find('i:nth-of-type(1)').get(0);
    const hamburgerMiddle = hamburger.find('i:nth-of-type(2)').get(0);
    const hamburgerBottom = hamburger.find('i:nth-of-type(3)').get(0);

    let menuOpen = false;
    let siteMenuOpen = false;
    let headroom = null;
    let activeElement = null;

    const hamburgerToX = () => {
        const offset = parseInt($(hamburgerTop).css('marginBottom'), 10) + 1;
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            y: offset * -1,
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 0 });
                gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
                gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
            }
        });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            rotationZ: '0deg',
            transformOrigin: '50% 50%',
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
                gsap.to(hamburgerBottom, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
            }
        });
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggleMenu();
        }
    };

    const openMenu = calledFromSearch => {
        activeElement = document.activeElement;
        html.addClass('js-menu-open');
        body.on('keyup', onBodyKeyUp);
        gsap.killTweensOf([menu]);
        gsap.to(menu, {
            duration: 0.35,
            autoAlpha: 1,
            ease: Linear.easeInOut,
            onComplete: () => {
                if (calledFromSearch) {
                    searchField.focus();
                }
            }
        });
        onFreeze();
        hamburgerToX();
        Viewport.lockTabbing([menu, header]);
    };

    const closeMenu = () => {
        html.removeClass('js-menu-open');
        body.off('keyup', onBodyKeyUp);
        gsap.killTweensOf([menu]);
        gsap.to(menu, {
            duration: 0.35,
            autoAlpha: 0,
            ease: Linear.easeInOut
        });
        onUnFreeze();
        xToHamburger();
        Viewport.releaseTabbing(activeElement);
    };

    const toggleSearch = e => {
        if (e) {
            e.preventDefault();
        }
        if (!menuOpen) {
            openMenu(true);
            menuOpen = true;
        }
    };

    const toggleMenu = e => {
        if (e) {
            e.preventDefault();
        }
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu(false);
        }
        menuOpen = !menuOpen;
    };

    const onFocusOutSiteMenu = e => {
        if (!siteMenu.contains(e.relatedTarget)) {
            toggleSiteMenu();
        }
    };

    const toggleSiteMenu = () => {
        if (siteMenuOpen) {
            siteToggle.attr('aria-expanded', false);
            siteMenu.off('focusout', onFocusOutSiteMenu);
        } else {
            siteToggle.attr('aria-expanded', true);
            siteMenu.on('focusout', onFocusOutSiteMenu);
        }
        siteMenuOpen = !siteMenuOpen;
    };

    const onUnpin = () => {
        if (siteMenuOpen) {
            siteToggle.attr('aria-expanded', false);
            siteMenu.off('focusout', onFocusOutSiteMenu);
            siteMenuOpen = false;
        }
    };

    const initHeadroom = () => {
        if (!headroom) {
            headroom = new Headroom(header, {
                tolerance: 5,
                onUnpin
            });
            headroom.init();
            headroom.pin();
            $(header).on('focusin', () => {
                headroom.pin();
            });
        }
    };

    const destroyHeadroom = () => {
        if (headroom) {
            headroom.destroy();
            headroom = null;
            $(header).off('focusin');
        }
    };

    const onResize = () => {
        const diff = document.body.scrollHeight - window.innerHeight;

        if (!headroom && diff >= 10) {
            initHeadroom();
        } else if (headroom && diff < 10) {
            destroyHeadroom();
        }
    };

    const onFreeze = () => {
        if (headroom) {
            headroom.freeze();
        }
    };

    const onUnFreeze = () => {
        if (headroom) {
            headroom.unfreeze();
        }
    };

    const init = () => {
        hamburger.on('click', toggleMenu);
        siteToggle.on('click', toggleSiteMenu);
        searchButton.on('click', toggleSearch);
        Dispatch.on(HEADER_FREEZE, onFreeze);
        Dispatch.on(HEADER_UNFREEZE, onUnFreeze);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        hamburger.off('click', toggleMenu);
        siteToggle.off('click', toggleSiteMenu);
        searchButton.off('click', toggleSearch);
        Dispatch.off(HEADER_FREEZE, onFreeze);
        Dispatch.off(HEADER_UNFREEZE, onUnFreeze);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
