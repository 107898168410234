import $ from '../core/Dom';

export default el => {
    const dom = $(el);

    const onTimeUpdate = () => {
        if (el.currentTime < 0.001) {
            return;
        }
        el.removeEventListener('timeupdate', onTimeUpdate);
        dom.removeClass('opacity-0');
    };

    const init = () => {
        el.addEventListener('timeupdate', onTimeUpdate);
        dom.attr('src', dom.data('src'));
    };

    const destroy = () => {
        el.removeEventListener('timeupdate', onTimeUpdate);
    };

    return {
        init,
        destroy
    };
};
