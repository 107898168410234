import gsap from 'gsap';
import $ from '../core/Dom';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default el => {
    const dom = $(el);
    const shapes = dom.find('[data-shape]').nodes;
    const panels = dom.find('[data-panel]').nodes;
    const content = dom.find('[data-content]').nodes;
    const logo = dom.find('[data-logo]').get(0);

    let needsToAddClasses = true;

    const getColorClasses = element => Array.from(element.classList).filter(className => (className.startsWith('bg-') || className.startsWith('text-')));
    const getBackgroundClasses = element => Array.from(element.classList).filter(className => className.startsWith('bg-'));

    const setColorClasses = panel => {
        console.log('setColorClasses', needsToAddClasses);
        el.classList.remove(...getColorClasses(el));
        el.classList.add(...panel.getAttribute('data-panel').split(' '));
        if (needsToAddClasses) {
            dom.addClass('transition-colors duration-950 ease-in-out-sine');
            needsToAddClasses = false;
        }
    };

    const init = () => {
        loadScrollTrigger(module => {
            const ScrollTrigger = module.default;
            gsap.registerPlugin(ScrollTrigger);

            shapes.forEach(shape => {
                const offsetX = gsap.utils.random(-128, 128, 24);
                const offsetY = gsap.utils.random(340, 400, 24);
                gsap.to(shape, {
                    x: `+=${offsetX}`,
                    y: `+=${offsetY}`,
                    scrollTrigger: {
                        start: 'top top',
                        end: 'bottom bottom',
                        scrub: 2
                    }
                });
            });

            content.forEach(element => {
                const timeline = gsap.timeline({ paused: true });
                timeline.to(element, { opacity: 1, duration: 1 });
                timeline.to(element, { opacity: 1, duration: 0.5 });
                timeline.to(element, { opacity: 0, duration: 1 });
                ScrollTrigger.create({
                    trigger: element,
                    start: 'top 75%',
                    end: 'bottom top',
                    scrub: 1,
                    animation: timeline
                });
            });

            panels.forEach((panel, index) => {
                panel.classList.remove(...getBackgroundClasses(panel));
                ScrollTrigger.create({
                    trigger: panel,
                    start: 'top 50%',
                    end: 'bottom 50%',
                    // markers: true,
                    /*onEnter: () => {
                        console.log('onEnter');
                        setColorClasses(panel);
                    },
                    onEnterBack: () => {
                        console.log('onEnterBack');
                        setColorClasses(panel);
                    },*/
                    onToggle: self => {
                        console.log('onToggle:', self);
                        if (self.isActive) {
                            setColorClasses(panel);
                        }
                    }
                });
                if (index === 0) {
                    ScrollTrigger.create({
                        trigger: panel,
                        start: 'top top',
                        end: 'top -25%',
                        scrub: 1,
                        animation: gsap.to(logo, {
                            autoAlpha: 0,
                            paused: true
                        })
                    });
                }
            });
        });
    };

    const destroy = () => {};

    return {
        init,
        destroy
    };
};
